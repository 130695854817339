import React from 'react';
import PropTypes from 'prop-types';
import VoteCard from './VoteCard';

import {Panel, PanelHeader, PullToRefresh, Placeholder, Group, CardGrid, Header, PanelHeaderButton, Spinner, PanelHeaderContent, PanelHeaderContext, List, SimpleCell , Cell, Card, Button, Tooltip} from '@vkontakte/vkui';
import InfiniteScroll from 'react-infinite-scroll-component';
import Caption from '@vkontakte/vkui/dist/components/Typography/Caption/Caption';


const Home = props => {

	async function refreshPolls() {
		await props.getStartPolls()
	}
	let polls = props.polls
	return <Panel id={props.id}>
		<PanelHeader>Опросы</PanelHeader>
		<PullToRefresh onRefresh={refreshPolls} isFetching={props.isFetching}>

				<Group header={<Header mode="secondary">Доступные опросы</Header>}>
				<CardGrid>
					<InfiniteScroll
					style={{overflow: "visible"}}
					dataLength={polls.length} //This is important field to render the next data
					next={() => props.addPolls()}
					hasMore={polls.length < props.allPollsCount}
					loader={<Spinner size="regular" style={{paddingTop: 20, paddingBottom: 20}}/>}
					endMessage={polls.length > 0 &&
						<Caption level="1" weight="regular" className='caption'>
							опросов: {polls.length}
						</Caption>
					}
					>
						{polls.length > 0?
						polls.map(vote => {
							return <div>
								<VoteCard data={vote} key={vote.id} size='l' height={150} acceptPoll={props.acceptPoll} delPoll={props.delPollRequest}/>
							</div>
						}): 
							null}
					</InfiniteScroll>
						
				</CardGrid>
				</Group>
				</PullToRefresh>

	</Panel>
};


export default Home;
