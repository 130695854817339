import React, {useContext} from 'react';
import PropTypes from 'prop-types';

import Caption from '@vkontakte/vkui/dist/components/Typography/Caption/Caption';

import './style.css';
import {Button, Card, Div, Title, Tooltip } from '@vkontakte/vkui';
import ProgressBar from './ProgressBar';
import { Icon24Cancel, Icon24FavoriteOutline, Icon24PollOutline, Icon24MoreHorizontal } from '@vkontakte/icons';

function calculate_procent(count_that, all_votes) {
    let result = (count_that / all_votes) * 100;
    if(!Number.isNaN(result)){
        return Math.round(result);

    }
    return 0
}

function getLabelVotes(count_votes) {
    let result = ''
    if(count_votes == 1){
        result += 'проголосовал '
    }
    else{
        result += 'проголосовали '
    }
    result += count_votes
    if(2 <= count_votes & count_votes <= 5){
        result += ' человека'
    }
    else{
        result += ' человек'
    }
    return result;
}

const VoteCard = ({data, key, size, acceptPoll, delPoll}) => {
    let result = null;
    if(data){
        let all_votes = data.answers.map(row => row.count).reduce(function(acc, val) { return acc + val; }, 0)
        let poll_val = data.answers.map(row => 

                                <div key={row.text} style={{paddingTop: '12px', paddingLeft: '12px'}}>
                                <ProgressBar hidden={true} text={row.text} width='calc(100% - 12px)' height={30} value={''}/>
                            </div>)
        
    if(poll_val){
        result = <Card 
                // onClick={() => (goToPage('poll'), setCurrentPoll(data))}
                size={size}
                key={key}
                id={key}
                className='vote-card'
                mode="shadow"> 
                            <Title level='2' weight='bold' className='vote-card-title'>{data.question}</Title>
                        {poll_val}
                <div style={{display: 'flex', justifyContent: 'center', paddingBottom: 10}}>
                <Button mode='commerce' onClick={() => acceptPoll(data.id)}>Одобрить</Button>
                <Button mode='destructive' onClick={() => delPoll(data.id)}>Отклонить</Button>
                </div>

             </Card>;
    }    
    
    }
    return result;
};

VoteCard.propTypes = {
    id: PropTypes.string,
    size: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
};

export default VoteCard;
