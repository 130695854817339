let baseURL = "/api.php";
let headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
};

class API_ClASS{
    constructor(vkParams) {
      this.vkParams = vkParams;
    };
  
    getDataBody(postdata){
      return Object.assign(postdata, this.vkParams);
    };

    prepareData(data){
        return new URLSearchParams(this.getDataBody(data))
    }
    
    async postData(data = {}) {
        const response = await fetch(baseURL, {
            method: 'POST',
            headers: headers,
            body: this.prepareData(data),
        })
        .then((response) => response.json())
        .catch(error => {
            console.error(error);
        });
        return await response
      }

    getResult(poll_id){
        return this.postData({m: 'getResult', poll_id: poll_id})
    }

    getPollsCount(user_id, filter) {
        return this.postData({m: 'getPollsCount', user_id:user_id, filter:filter})
    }
  
    addPoll(user_id, moder, question, answers, colors){
        return this.postData({m: 'addPoll', user_id: user_id, moder: moder, question: question, answers: answers, colors:colors})
    }

    addVote(user_id, poll_id, region, answer){
        return this.postData({m: 'addVote', user_id: user_id, poll_id: poll_id, region: region, answer: answer})
    }
  
    getPolls(user_id, filter='all', count=10, offset=0, ){
        return this.postData({m: 'getPolls', user_id:user_id, count: count, offset: offset, filter:filter})
    };

    getPollById(user_id, poll_id){
        return this.postData({m: 'getPollById', user_id:user_id, poll_id: poll_id})
    };

    delVote(user_id, poll_id){
        return this.postData({m: 'delVote', user_id: user_id, poll_id: poll_id})
    }

    delPoll(poll_id){
        return this.postData({m: 'delPoll', poll_id: poll_id})
    }

    addUser(user_id, region){
        return this.postData({m: 'addUser', user_id: user_id, region: region})
    }

    getUser(user_id){
        return this.postData({m: 'getUser', user_id: user_id})
    }

    setUserValue(user_id, value_name, value){
        return this.postData({m: 'setUserValue', user_id: user_id, value_name:value_name, value:value})

    }
    isUserExists(user_id){
        return this.postData({m: 'isUserExists', user_id: user_id})
    }
    setPollValue(poll_id, value_name, value){
        return this.postData({m: 'setPollValue', poll_id: poll_id, value_name:value_name, value:value})

    }
  }

  export default API_ClASS;