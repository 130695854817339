import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './style.css';
import Icon16Done from '@vkontakte/icons/dist/16/done';

const ProgressBar = props => {
    let width = props.hidden ? '0%' : props.value.toString() + '%'
    return (
        <div id={props.id} style={{width: props.width, height: props.height}} className='progress-bar' onClick={props.onClick}>
            <div className={props.hidden?'progress-bar-background hidden-progress-bar-background':'progress-bar-background'}
                style={{height: '100%', width: width, backgroundColor: props.color}}
            >
                <div className='progress-bar-text'>
                    <span>{props.text}</span>
                </div>
                <div className='progress-bar-value'>
                    {props.voted ? <Icon16Done/> : null}
                    <span>{props.value !== ''? props.value.toString() + '%' : ''}</span>
                </div>
            </div>
        </div>
    );
}
ProgressBar.propTypes = {
    id: PropTypes.string,
    text: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    value: PropTypes.number,
    color: PropTypes.string,
    voted: PropTypes.bool.isRequired,
};

export default ProgressBar;
