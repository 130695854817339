import React, { useState, useEffect } from 'react';
import bridge from '@vkontakte/vk-bridge';
import View from '@vkontakte/vkui/dist/components/View/View';
import ScreenSpinner from '@vkontakte/vkui/dist/components/ScreenSpinner/ScreenSpinner';
import '@vkontakte/vkui/dist/vkui.css';
import API_CLASS from './Api'

import Home from './panels/Home';

const api = new API_CLASS();


const App = () => {
	const [activePanel, setActivePanel] = useState('home');
	const [fetchedUser, setUser] = useState(null);
	const [popout, setPopout] = useState(<ScreenSpinner size='large' />);
	const [allPollsCount, setAllPollsCount] = useState(0)
	const [isFetching, setIsFetching] = useState(false);


	const [polls, setPolls] = useState(
		[]
	);

	useEffect(() => {
		bridge.subscribe(({ detail: { type, data }}) => {
			if (type === 'VKWebAppUpdateConfig') {
				const schemeAttribute = document.createAttribute('scheme');
				schemeAttribute.value = data.scheme ? data.scheme : 'client_light';
				document.body.attributes.setNamedItem(schemeAttribute);
			}
		});
		async function fetchData() {
			const user = await bridge.send('VKWebAppGetUserInfo');
			setUser(user);
			setPopout(null);
		}
		fetchData();
		getStartPolls()
	}, []);

	async function getStartPolls() {
		setIsFetching(true)
		api.getPolls(1, 'moder')
		.then(res => {
			setIsFetching(false)
			setAllPollsCount(res['count'])
			setPolls(res['items'])})
		.catch(error => console.log(error))
		.finally(() => {
			setIsFetching(false)
			setPopout(null)})
	}

	async function delPollRequest(poll_id) {
		setPopout(<ScreenSpinner/>)
		await api.delPoll(poll_id)
		.then(res => {
			if(res == 0){
				let index = polls.findIndex(x => x.id === poll_id);
				polls.splice(index, 1)				
			}
		})
		.catch(error => console.log(error))
		.finally(() => setPopout(null))
		await api.getPollsCount(1, 'moder')
				.then(res => setAllPollsCount(res))
				.catch(error => console.log(error))
			}

	async function acceptPoll(poll_id){
		setPopout(<ScreenSpinner/>)
		api.setPollValue(poll_id, 'moder', 0)
		.then(() => {
			let index = polls.findIndex(x => x.id === poll_id);
				polls.splice(index, 1)	
		}
		)
		.finally(
			() => setPopout(null)
		)
	}

	function addPolls() {
		api.getPolls(1, 5, polls.length).then(res => {
			setPolls(oldArray => [...oldArray].concat(res['items']));
		})
	}


	return (
		<View activePanel={activePanel} popout={popout}>
			<Home id='home' 
			polls={polls}
			getStartPolls={getStartPolls}
			acceptPoll={acceptPoll}
			delPollRequest={delPollRequest}
			isFetching={isFetching}
			addPolls={addPolls} />
		</View>
	);
}

export default App;

